export const CONSENT_COOKIE_NAME = `cookie-consent-opt-in`

export function isMissingCookies(cookies = {}, cookieNames = []) {
  return (
    cookieNames.filter(name => typeof cookies[name] === `undefined`).length > 0
  )
}

export function deleteCookie(name, setCookie) {
  setCookie(name, `deleted`, {
    path: `/`,
    maxAge: 0,
    expires: new Date(`Thu, 01 Jan 1970 00:00:00 GMT`),
  })
}

/**
 * @param {string} gaMeasurementId
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out#:~:text=To%20disable%20Analytics%20programmatically%2C%20set,you%20would%20like%20to%20disable.
 */
export function disableGoogleAnalytics(gaMeasurementId) {
  window[`ga-disable-${gaMeasurementId}`] = true
}

export function consentGranted() {
  /* istanbul ignore next */
  if (typeof window.gtag === `function`) {
    window.gtag(`consent`, `update`, {
      ad_storage: `granted`,
      analytics_storage: `granted`,
      functionality_storage: `granted`,
      personalization_storage: `granted`,
    })
    window.gtag(`event`, `gtm.init_consent`)
  }
  /* istanbul ignore next */
  if (typeof window.fbq === `function`) window.fbq(`consent`, `grant`)
}

export function consentDenied() {
  /* istanbul ignore next */
  if (typeof window.gtag === `function`) {
    window.gtag(`consent`, `update`, {
      ad_storage: `denied`,
      analytics_storage: `denied`,
      functionality_storage: `denied`,
      personalization_storage: `denied`,
    })
    window.gtag(`event`, `gtm.init_consent`)
  }
  /* istanbul ignore next */
  if (typeof window.fbq === `function`) window.fbq(`consent`, `revoke`)
}

export function cookiesSessionStorage(hasConsented, disableCookies) {
  window.sessionStorage.setItem(`_consent`, hasConsented)
  window.sessionStorage.setItem(`_disable-cookies`, disableCookies)
}
