import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import initSmartOutline from "js-utils/src/smart-outline"

import Meta from "../components/meta"
import CookiesConsent from "../components/cookies-consent"
import theme from "../theme"

import "../assets/stylesheets/fonts/woff.scss"
import "../assets/stylesheets/app/app.scss"

class Layout extends React.Component {
  componentDidMount() {
    /* istanbul ignore next */
    if (typeof document !== `undefined`) {
      initSmartOutline([
        `input:focus`,
        `button:focus`,
        `textarea:focus`,
        `select:focus`,
      ])
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <Meta />

          <main>{this.props.children}</main>

          <CookiesConsent />
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
