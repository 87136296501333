module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Clickotine®","short_name":"Clickotine","description":"Clickotine is a clinically validated fully digital smoking cessation program.","theme_color":"#ee755f","background_color":"#333","icon":"static/meta-images/icon-with-bg.png","include_favicon":false,"start_url":"/","display":"minimal-ui","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"cacheDigest":"790b828758f7df88110984d2f3f1062f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
