// works in pair with `gatsby-plugin-gdpr-cookies` plugin

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useCookies } from "react-cookie"
import { rem } from "polished"
import {
  CONSENT_COOKIE_NAME,
  consentDenied,
  consentGranted,
  cookiesSessionStorage,
  deleteCookie,
  isMissingCookies,
} from "../utils/cookies"

const Container = styled.div`
  width: 100%;
  position: fixed;
  z-index: ${props => props.theme.ziCookiesConsent};
  bottom: 0;
  right: 0;
  padding: ${rem(30)};
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  @media ${props => props.theme.xsmallDown} {
    padding: ${rem(10)};
  }
`

const Box = styled.div`
  width: 100%;
  max-width: 20em;
  padding: 1.4em;
  pointer-events: auto;
  color: ${props => props.theme.colorWhite};
  background-color: ${props => props.theme.colorBlack};
  border: 1px solid rgba(255, 255, 255, 0.1);
`

const Text = styled.p`
  margin-bottom: 1em;
`

const Cta = styled.div`
  display: flex;

  > button {
    padding: 0.5em 1em 0.8em;
    height: 2.6em;
    font-size: 0.875em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border: 1px solid;
    border-radius: 2em;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    &:first-child {
      margin-right: 0.5em;
      border-width: 2px;
      font-weight: ${props => props.theme.fwPrimaryBold};
    }
  }
`

const CookiesConsent = ({
  disclaimer,
  ctaAccept,
  ctaDeny,
  children,
  ...props
}) => {
  const [cookies, setCookie] = useCookies()
  const [hasConsented, setHasConsented] = useState(false)

  /* istanbul ignore next */
  if (typeof window === `undefined`) return null

  useEffect(() => {
    const consent = window.sessionStorage.getItem(`_consent`)
    if (consent === `true`) {
      setHasConsented(true)
    }
  }, [])

  useEffect(() => {
    let timeout
    if (hasConsented) {
      const disableCookies = window.sessionStorage.getItem(`_disable-cookies`)
      if (disableCookies === `true`) {
        deleteCookie(CONSENT_COOKIE_NAME, setCookie)
        timeout = requestAnimationFrame(() => setTimeout(consentDenied, 750))
      } else {
        setCookie(CONSENT_COOKIE_NAME, true, {
          path: `/`,
          maxAge: 3600 * 24 * 30 * 12, // year
        })
        timeout = requestAnimationFrame(() => setTimeout(consentGranted, 750))
      }
    }
    return () => {
      if (timeout) cancelAnimationFrame(timeout)
    }
  }, [hasConsented])

  if (hasConsented) {
    return null
  }

  if (!isMissingCookies(cookies, [CONSENT_COOKIE_NAME]) && !hasConsented) {
    cookiesSessionStorage(true, false)
    setHasConsented(true)
    return null
  }

  function handleAcceptClick() {
    cookiesSessionStorage(true, false)
    setHasConsented(true)
  }

  function handleRejectClick() {
    cookiesSessionStorage(true, true)
    setHasConsented(true)
  }

  return (
    <Container {...props} data-testid="cookie-disclaimer">
      <Box>
        <Text
          dangerouslySetInnerHTML={{
            __html:
              disclaimer ||
              children ||
              `Cookies will improve your browsing experience. Would you like to accept the cookies?`,
          }}
        />

        <Cta>
          <button
            className="cc-accept"
            data-testid="cookies-accept-button"
            type="button"
            onClick={handleAcceptClick}
            dangerouslySetInnerHTML={{ __html: ctaAccept || `Accept` }}
          />

          <button
            className="cc-deny"
            data-testid="cookies-reject-button"
            type="button"
            onClick={handleRejectClick}
            dangerouslySetInnerHTML={{ __html: ctaDeny || `Deny` }}
          />
        </Cta>
      </Box>
    </Container>
  )
}

CookiesConsent.propTypes = {
  children: PropTypes.string,
  disclaimer: PropTypes.string,
  ctaAccept: PropTypes.string,
  ctaDeny: PropTypes.string,
}

export default CookiesConsent
